import axios from "axios";

var storageToken = localStorage.getItem("token");
var backupStorageToken = localStorage.getItem("backup");
if (storageToken === null) {
  var tokenAccess = "";
} else {
  var token = JSON && JSON?.parse(storageToken);
  var tokenAccess = token && token.access;
}
//for franchise switch when logged in is franchise we need to use admin token where it is in backup
if (backupStorageToken === null) {
  var backuptokenAccess = "";
} else {
  var backuptoken = JSON && JSON.parse(backupStorageToken);
  var backuptokenAccess = backuptoken && backuptoken.access;
}
//ends

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Authorization: `Bearer ${tokenAccess}` },
});

export const franchiseaxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL_FRANCHISE,
  headers: { Authorization: `Bearer ${tokenAccess}` },
});

export const helpdeskaxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL_HELPDESK,
  headers: { Authorization: `Bearer ${tokenAccess}` },
});

export const adminaxios = axios.create({
  // baseURL: process.env.REACT_APP_API_URL_ADMIN,
  baseURL: "https://admin.vbctv.ott.sparkradius.in:7001/",
  headers: { Authorization: `Bearer ${tokenAccess}` },
});

export const adminaxiosWithoutToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL_ADMIN,
});

export const servicesaxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL_SERVICES,
  headers: { Authorization: `Bearer ${tokenAccess}` },
});

export const ottaxios = axios.create({
  // baseURL: "http://123.108.200.199:8563",
  baseURL: "https://admin.vbctv.ott.sparkradius.in:7003/",
  headers: {
    // Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE0Njg0Nzc1LCJpYXQiOjE3MTIwOTI3NzUsImp0aSI6ImVlMzI4YzdlYjViNzRhMjRhODNiMTcyZDhjMGE5MGIwIiwidXNlcl9pZCI6MTR9.Jrjr1AOAG28LyVbuONyqYsg-5ZayTPDhusTt1TUs7TM`,
    // Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE2MzE1MzgwLCJpYXQiOjE3MTM3MjMzODAsImp0aSI6ImZmN2MxNWFmMDM4ZTRmOWRhODc3OTk2ZTMxNjk4YWI3IiwidXNlcl9pZCI6MTV9.Kc-mbzZEWDPCvSAqOZ1dKYZyj9jdh5ocOjX4XgTjT7U`,
    // Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE2NDczOTMyLCJpYXQiOjE3MTM4ODE5MzIsImp0aSI6IjUyMDE1YTk3OWZiYzQ2Y2JhZmI4ZTlmM2FkMDA3YzMwIiwidXNlcl9pZCI6MTR9.omkRRPHnHZuhl-J7BtasWthzAkdGF7rHMgYwFRv-0K0`,
    // Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE3MTgxOTc3LCJpYXQiOjE3MTQ1ODk5NzcsImp0aSI6ImViYmVmMDg5ZGQ0NjRhY2ZiOTFmYjg2NDUwMGE0ODA0IiwidXNlcl9pZCI6MTV9.pWtNFdkkiWs0UNywtwXnUuQDFAMJQAG0oFizavnP6hI`,
    Authorization: `Bearer ${tokenAccess}`,
  },
});

export const networkaxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL_NETWORK,
  headers: { Authorization: `Bearer ${tokenAccess}` },
});

export const customeraxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL_CUSTOMER,
  headers: { Authorization: `Bearer ${tokenAccess}` },
});

export const customeraxiosForm = axios.create({
  baseURL: process.env.REACT_APP_API_URL_CUSTOMER,
  headers: {
    Authorization: `Bearer ${tokenAccess}`,
    "content-type": "application/json",
  },
});

export const billingaxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL_BILLING,
  headers: { Authorization: `Bearer ${tokenAccess}` },
});

export const staffaxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL_STAFF,
  headers: { Authorization: `Bearer ${tokenAccess}` },
});

export const campaignaxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL_CAMPAIGN,
  headers: { Authorization: `Bearer ${tokenAccess}` },
});

export const iptvaxios = axios.create({
  baseURL: "http://125.62.213.82:9081/admin/",
  headers: { Authorization: `Bearer ${localStorage.getItem("iptvtoken")}` },
});

//for franchise switch when logged in is franchise we need to use admin token where it is in backup
export const adminaxiosFranchiseSwitch = axios.create({
  baseURL: process.env.REACT_APP_API_URL_ADMIN,
  headers: { Authorization: `Bearer ${backuptokenAccess}` },
});
export const franchiseaxiosSwitch = axios.create({
  baseURL: process.env.REACT_APP_API_URL_FRANCHISE,
  headers: { Authorization: `Bearer ${backuptokenAccess}` },
});
// export const webSocket = axios.create({
//   baseURL: process.env.REACT_APP_WS_URL,
//   headers: {"Authorization" : `Bearer ${tokenAccess}`}
// });
