import React, { useState, useEffect, useRef, useMemo } from "react";
import { ServiceHeaderButtons } from "./header-buttons";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { classes } from "../../../../data/layouts";
import { TabContent, TabPane } from "reactstrap";
import { ottaxios, servicesaxios } from "../../../../axios";
import Skeleton from "react-loading-skeleton";
import DataTable from "react-data-table-component";
import { logout_From_Firebase } from "../../../../utils";
import {
  getServiceListsTableColumns,
  getAppliedServiceFiltersObj,
} from "../data";
import AddServicePlan from "../addserviceplan";
import { NewServiceDetails } from "../NewServiceDestails";
import NewServiceUtilityBadge from "../../../utilitycomponents/NewServiceUtilityBadge";
import { toast } from "react-toastify";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../table.css";
import tableHeaders from "./table-headers";

import Row from "./combo-plans-row";
import AddComboPlan from "./add-combo-plan";
import Pagination from "../../../common/pagination/pagination-component";
import PlanDetailsContainer from "./plan-details-container";
import ConfirmationModal from "../../../common/confirmation-modal/ConfirmationModal";
import { COMBO_PLANS } from "../../../../utils/permissions";

const ComboPlans = () => {
  const [serviceLists, updateserviceLists] = useState({
    currentPageNo: 1,
    currentItemsPerPage: 10,
    pageLoadData: [],
    pageLoadDataForFilter: [],
    prevURI: null,
    nextURI: null,
    currentTab: "ACT",
    tabCounts: {},
    totalRows: 20,
    appliedServiceFilters: { ...getAppliedServiceFiltersObj() },
  });

  const unsortedPageLoadData = useRef();
  const apiResponse = useRef();

  const [showHidePermissionModal, setShowHidePermissionModal] = useState(false);
  const [activeTab, setActiveTab] = useState("ACT");
  const [selectedRow, setSelectedRow] = useState({});
  const [isServiceDetailsOpen, setIsServiceDetailsOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [internetAndOttPlans, setInternetAndOttPlans] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [permissionModalText, setPermissionModalText] = useState(
    "You are not authorized for this action"
  );
  const [loading, setLoading] = useState(false);
  const [sortedColumn, setSortedColumn] = useState({});
  const [allItemsSelected, setAllItemsSelected] = useState(false);
  const [counts, setCounts] = useState({
    active: 0,
    inactive: 0,
  });

  // pagination
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(10);

  // combo buttons
  const [addCombo, setAddCombo] = useState(0);
  // slide panel
  const [activeTab1, setActiveTab1] = useState("1");
  const configDB = useSelector((content) => content.Customizer.customizer);
  const [sidebar_type, setSidebar_type] = useState(
    configDB.settings.sidebar.type
  );
  const [serviceSelectedCheckboxObjects, setServiceSelectedCheckboxObjects] =
    useState({});
  var updateBysub = false;

  let history = useHistory();
  let DefaultLayout = {};
  const dispatch = useDispatch();

  useEffect(() => {
    const defaultLayoutObj = classes.find(
      (item) => Object.values(item).pop(1) === sidebar_type
    );

    const id =
      window.location.pathname === "/"
        ? history.push()
        : window.location.pathname.split("/").pop();
    // fetch object by getting URL
    const layoutobj = classes.find((item) => Object.keys(item).pop() === id);
    const layout = id ? layoutobj : defaultLayoutObj;
    DefaultLayout = defaultLayoutObj;
    handlePageLayputs(layout);
  }, []);

  const handlePageLayputs = (type) => {
    let key = Object.keys(type).pop();
    let val = Object.values(type).pop();
    document.querySelector(".page-wrapper").className = "page-wrapper " + val;
    localStorage.setItem("layout", key);
    history.push(key);
  };

  const openCustomizer = (type) => {
    setActiveTab1(type);
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.add("open");
  };

  const closeCustomizer = () => {
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.remove("open");
  };
  const togglePermissionModal = () =>
    setShowHidePermissionModal(!showHidePermissionModal);
  // getQueryParams for tabs

  const getQueryParams = () => {
    const { currentPageNo } = serviceLists;
    return currentPageNo;
  };

  // get API

  const fetchComboPlans = () => {
    setLoading(true);
    ottaxios
      .get(`combo/get_plans/${getQueryParams()}`)
      .then((response) => {
        console.log("res===", response);
        const {
          data: {
            data: { status, count, results },
          },
        } = response;
        const parsedResults = results.map((row, id) => ({
          ...row,
          id,
        }));
        updateserviceLists((prevState) => ({
          ...prevState,
          pageLoadData: parsedResults,
          pageLoadDataForFilter: [...results],
          totalRows: count || 1,
        }));
        apiResponse.current = parsedResults;
        const activeCount = results.filter(
          (row) => row.plan_status === "active"
        ).length;
        setCounts({
          active: activeCount,
          inactive: results.length - activeCount,
        });
      })
      .catch((error) => {
        console.log("error====", error.response);
        if (error.response.data.message) {
          toast.error(error.response.data.message, { autoClose: 2000 });
          return;
        }
        let errorMessage = "Something went wrong";
        if (error.response && error.response.data) {
          if (error.response.data["package_name"].length > 0) {
            errorMessage = error.response.data["package_name"][0];
          } else if (error.response.status === 500) {
            errorMessage = "Internal server error";
          } else if (error.response.status === 404) {
            errorMessage = "API endpoint not found";
          } else if (error.response.status === 401) {
            errorMessage = "Unauthorized!";
          }
        }
        toast.error(errorMessage, { autoClose: 2000 });
      })

      .finally(function () {
        setLoading(false);
      });
  };

  useEffect(() => {
    updateserviceLists((prev) => ({
      ...prev,
      pageLoadData: prev.pageLoadData.map((row) => ({
        ...row,
        isSelected: serviceSelectedCheckboxObjects[row.id],
      })),
    }));
  }, [serviceSelectedCheckboxObjects]);

  useEffect(() => {
    unsortedPageLoadData.current = serviceLists.pageLoadData;
  }, [serviceLists.pageLoadData]);

  // const resetSearch = () => {
  //   if (serviceLists.pageLoadData.length)
  //     updateserviceLists((prev) => ({
  //       ...prev,
  //       pageLoadData: unsortedPageLoadData.current,
  //     }));
  // };

  const handleServiceSelectedRows = (row, isSelected) => {
    if (!isSelected) setAllItemsSelected(false);
    let selectedCheckboxes = { ...serviceSelectedCheckboxObjects };
    if (isSelected && !selectedCheckboxes[row.id]) {
      selectedCheckboxes[row.id] = true;
    }
    if (!isSelected && selectedCheckboxes[row.id]) {
      delete selectedCheckboxes[row.id];
    }
    setServiceSelectedCheckboxObjects({ ...selectedCheckboxes });
    if (Object.keys(selectedCheckboxes).length === 1) {
      setSelectedRow(row);
    } else setSelectedRow({});
  };

  useEffect(() => {
    fetchComboPlans();
  }, [
    serviceLists.currentPageNo,
    serviceLists.currentItemsPerPage,
    refresh,
    serviceLists.appliedServiceFilters,
  ]);

  const handleServicePerRowsChange = (newPerPage, page) => {
    updateserviceLists((prevState) => ({
      ...prevState,
      currentPageNo: page,
      currentItemsPerPage: newPerPage,
    }));
  };

  const handleServicePageChange = (page) => {
    updateserviceLists((prevState) => ({
      ...prevState,
      currentPageNo: page,
    }));
  };

  const handleSelectAll = (selectAll) => {
    const checkboxObjects = (serviceLists.pageLoadData || []).reduce(
      (acc, row) => {
        acc[row.id] = true;
        return acc;
      },
      {}
    );
    setServiceSelectedCheckboxObjects(selectAll ? checkboxObjects : {});
    setAllItemsSelected(selectAll);
  };

  // side panel
  const serviceIdClickHandler = (row) => {
    setSelectedRow(row);
    setIsServiceDetailsOpen(true);
  };

  const closeCustomizer1 = () => {
    setRightSidebar(!rightSidebar);
    setIsServiceDetailsOpen(false);
  };

  const detailsUpdate = () => {
    RefreshHandler();
    closeCustomizer1();
  };

  const logout = () => {
    logout_From_Firebase();
    history.push(`${process.env.PUBLIC_URL}/login`);
  };
  const searchInputField = useRef(null);

  //refresh page
  const RefreshHandler = () => {
    setRefresh((prevValue) => prevValue + 1);
    if (searchInputField.current) searchInputField.current.value = "";
  };

  useEffect(() => {
    updateserviceLists((prev) => ({
      ...prev,
      pageLoadData: (apiResponse.current || []).filter(
        (row) => row.plan_status?.toLowerCase() === activeTab.toLowerCase()
      ),
    }));
    setServiceSelectedCheckboxObjects({});
    setSelectedRow({});
    setAllItemsSelected(false);
  }, [activeTab]);

  const sortDataByColumn = (displayName, columnName, cb) => {
    let sortedData = [];
    let order = "asc";

    if (sortedColumn.name === displayName) {
      order = sortedColumn.order === "asc" ? "des" : "asc";
      if (order === "asc") {
        sortedData = [...unsortedPageLoadData.current].sort((a, b) => {
          if (cb) {
            return cb(a) >= cb(b) ? 1 : -1;
          } else return a[columnName] >= b[columnName] ? 1 : -1;
        });
      } else {
        sortedData = [...unsortedPageLoadData.current].sort((a, b) => {
          if (cb) {
            return cb(a) <= cb(b) ? 1 : -1;
          } else return a[columnName] <= b[columnName] ? 1 : -1;
        });
      }
    } else {
      sortedData = [...unsortedPageLoadData.current].sort((a, b) => {
        if (cb) {
          return cb(a) >= cb(b) ? 1 : -1;
        } else return a[columnName] >= b[columnName] ? 1 : -1;
      });
    }
    setSortedColumn({ name: displayName, order });
    updateserviceLists((prev) => ({
      ...prev,
      pageLoadData: [...sortedData],
    }));
  };

  const sortData = (name) => {
    switch (name) {
      case "Plan Name": {
        sortDataByColumn("Plan Name", "plan_name");
        break;
      }
      case "Plan Cost": {
        sortDataByColumn("Plan Cost", "basic");
        break;
      }
      case "Plan CGST": {
        sortDataByColumn("Plan CGST", "basic");
        break;
      }
      case "Plan SGST": {
        sortDataByColumn("Plan SGST", "basic");
        break;
      }
      case "Total Cost": {
        sortDataByColumn("Total Cost", "total");
        break;
      }
    }
  };

  const getSortIconName = (name) => {
    if (sortedColumn.name === name) {
      return sortedColumn.order === "asc" ? "down" : "up";
    }
    return "up";
  };

  const deleteSelectedPlan = () => {
    setLoading(true);
    ottaxios
      .delete(`/ott/combo/delete_plan/${selectedRow.plan_code}`)
      .then((res) => {
        if (res.data.status === "failure") {
          toast.error(`Error: ${res.data.message}`, { autoClose: 2000 });
        } else {
          toast.success("Plan Deleted Successfully!", { autoClose: 2000 });
          RefreshHandler();
        }
      })
      .catch((error) => {
        console.log("error====", error.response);
        if (error.response.data.message) {
          toast.error(error.response.data.message, { autoClose: 2000 });
          return;
        }
        let errorMessage = "Something went wrong";
        if (error.response && error.response.data) {
          if (error.response.data["package_name"].length > 0) {
            errorMessage = error.response.data["package_name"][0];
          } else if (error.response.status === 500) {
            errorMessage = "Internal server error";
          } else if (error.response.status === 404) {
            errorMessage = "API endpoint not found";
          } else if (error.response.status === 401) {
            errorMessage = "Unauthorized!";
          }
        }
        toast.error(errorMessage, { autoClose: 2000 });
      })
      .finally(() => {
        setLoading(false);
        setShowDeleteModal(false);
      });
  };

  return (
    <>
      <div style={{ paddingTop: "20px" }}>
        <Grid container spacing={1} id="breadcrumb_margin">
          <Grid item md="12">
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <NavigateNextIcon fontSize="small" className="navigate_icon" />
              }
            >
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color=" #377DF6"
                fontSize="14px"
              >
                Business Operations
              </Typography>
              {/* Sailaja Changed  Service Plan Color from Breadcrumbs  on 13th July */}

              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color=" #00000"
                fontSize="14px"
                className="last_typography"
              >
                Service Plan
              </Typography>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="#00000 !important"
                fontSize="14px"
                className="last_typography"
              >
                Combo Plans
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid
          container
          spacing={1}
          className="data_table"
          id="breadcrumb_table"
        >
          <Grid item md="12">
            <ServiceHeaderButtons
              serviceLists={serviceLists}
              openCustomizer={openCustomizer}
              RefreshHandler={RefreshHandler}
              setAddCombo={setAddCombo}
              addCombo={addCombo}
              activeTab={activeTab}
              updateserviceLists={updateserviceLists}
              // resetSearch={resetSearch}
              onDeletePress={() => setShowDeleteModal(true)}
              isEditDisabled={
                Object.keys(serviceSelectedCheckboxObjects).length !== 1
              }
              isDeleteDisabled={
                Object.keys(serviceSelectedCheckboxObjects).length !== 1 ||
                selectedRow.plan_status.toLowerCase() === "active"
              }
            />
          </Grid>
          <Grid item md="12">
            <NewServiceUtilityBadge
              currentTab={activeTab}
              setActiveTab={setActiveTab}
              tabCounts={counts}
            />
          </Grid>
          <Grid item md="12">
            {loading ? (
              <SkeletonLoader loading={loading} />
            ) : (
              <table width="100%" style={{ borderRadius: "50px" }}>
                <thead>
                  <tr className="table-header-row">
                    {tableHeaders.map((header, idx) => (
                      <th
                        className="table-header"
                        style={{
                          textAlign: "center",
                          paddingLeft: idx === 0 ? 40 : 0,
                          paddingRight: 0,
                        }}
                        key={idx}
                      >
                        <div
                          className="flex"
                          style={
                            idx === 0 ? { justifyContent: "flex-start" } : {}
                          }
                        >
                          <div>
                            {idx === 0 ? (
                              <input
                                type="checkbox"
                                style={{ marginRight: "20px" }}
                                checked={allItemsSelected}
                                onChange={(e) =>
                                  handleSelectAll(e.target.checked)
                                }
                              />
                            ) : null}
                            {header.name}
                          </div>
                          {header.sortable ? (
                            <div
                              onClick={() => sortData(header.name)}
                              className={`${getSortIconName(
                                header.name
                              )}-arrow`}
                            />
                          ) : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {(serviceLists.pageLoadData || []).map((row) => (
                    <Row
                      key={row.id}
                      row={row}
                      onSelectRow={handleServiceSelectedRows}
                      serviceIdClickHandler={serviceIdClickHandler}
                    />
                  ))}
                </tbody>
              </table>
            )}
          </Grid>
          <Grid item md="12">
            <Pagination
              currentPageNo={serviceLists.currentPageNo}
              currentRowsPerPage={serviceLists.currentItemsPerPage}
              handlePageChange={handleServicePageChange}
              handleRowsPerPageChange={handleServicePerRowsChange}
              totalCount={serviceLists.totalRows}
            />
          </Grid>

          <Grid>
            {isServiceDetailsOpen && (
              <PlanDetailsContainer
                detailsUpdate={detailsUpdate}
                isServiceDetailsOpen={isServiceDetailsOpen}
                closeCustomizer1={closeCustomizer1}
                selectedRow={selectedRow}
                RefreshHandler={RefreshHandler}
              />
            )}
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item md="12">
            <div
              className="customizer-contain"
              style={{
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
              }}
            >
              <div className="tab-content" id="c-pills-tabContent">
                <div
                  className="customizer-header"
                  style={{
                    border: "none",
                    padding: "30px 25px",
                    borderTopLeftRadius: "20px",
                  }}
                >
                  <i
                    className="icon-close"
                    style={{ position: "absolute", top: "20px" }}
                    onClick={closeCustomizer}
                  ></i>
                </div>
              </div>

              <div className="tab-content" id="c-pills-tabContent">
                <div className=" customizer-body custom-scrollbar">
                  <TabContent activeTab={activeTab1}>
                    <TabPane tabId="2">
                      <div id="headerheading"> Add New Plan </div>
                      <ul
                        className="layout-grid layout-types"
                        style={{ border: "none" }}
                      >
                        <li
                          data-attr="compact-sidebar"
                          onClick={(e) => handlePageLayputs(classes[0])}
                        >
                          <div className="layout-img">
                            {activeTab1 == "2" && (
                              <AddComboPlan
                                dataClose={closeCustomizer}
                                onUpdate={detailsUpdate}
                                // internetAndOttPlans={internetAndOttPlans}
                                rightSidebar={rightSidebar}
                              />
                            )}
                          </div>
                        </li>
                      </ul>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        {showDeleteModal ? (
          <ConfirmationModal
            title="Delete Plan"
            subtitle="Are you sure you want to delete the selected plan ?"
            onConfirm={deleteSelectedPlan}
            onCancel={() => setShowDeleteModal(false)}
          />
        ) : null}
      </div>
    </>
  );
};
const SkeletonLoader = ({ loading }) => {
  const tableData = useMemo(
    () => (loading ? Array(10).fill({}) : []),
    [loading]
  );

  return (
    <Box sx={{ width: "100vw", pl: 2, pr: 2 }}>
      {tableData.map((_) => (
        <Skeleton height={50} />
      ))}
    </Box>
  );
};
export default ComboPlans;
