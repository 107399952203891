import React, { Fragment, useState, useRef, useEffect, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import useFormValidation from "../../../customhooks/FormValidation";
import { ottaxios } from "../../../../axios";

import { toast } from "react-toastify";

import ErrorModal from "../../../common/ErrorModal";

const requiredFields = ["phone_number", "vbc_customer_id"];

const AddComboPlan = (props) => {
  const [isShow, setIsShow] = React.useState(false);
  const [loaderSpinneer, setLoaderSpinner] = useState(false);
  const [formData, setFormData] = useState({
    phone_number: "",
    vbc_customer_id: "",
  });

  const [errors, setErrors] = useState({});
  const [isDisabled, setDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [customers, setCustomers] = useState([]);
  const [showCustomers, setShowCustomers] = useState(false);

  const filteredCustomers = useMemo(() => {
    const text = formData.vbc_customer_id;
    return customers.filter(
      (customer) =>
        text.length && customer.toLowerCase().startsWith(text.toLowerCase())
    );
  }, [customers, formData.vbc_customer_id]);

  useEffect(() => {
    if (!customers.length) {
      setLoaderSpinner(true);
      ottaxios
        .get("/combo/get_customer_usernames")
        .then((res) => {
          if (res.data.status === "failure") {
            toast.error(`Error: ${res.data.message}`, { autoClose: 2000 });
          } else {
            console.log("res====", res.data.data);
            setCustomers(res.data.data);
          }
          setLoaderSpinner(false);
        })
        .catch((error) => {
          console.log("error====", error);
          setLoaderSpinner(false);
          if (error.response.data.message) {
            toast.error(error.response.data.message, { autoClose: 2000 });
            return;
          }
          let errorMessage = "Something went wrong";
          if (error.response && error.response.data) {
            if (error.response.data["package_name"].length > 0) {
              errorMessage = error.response.data["package_name"][0];
            } else if (error.response.status === 500) {
              errorMessage = "Internal server error";
            } else if (error.response.status === 404) {
              errorMessage = "API endpoint not found";
            } else if (error.response.status === 401) {
              errorMessage = "Unauthorized!";
            }
          }
          toast.error(error.data.message, { autoClose: 2000 });
        });
    }
  }, []);

  useEffect(() => {
    const hasEmptyValue = requiredFields.some(
      (field) => formData[field] === ""
    );
    if (hasEmptyValue || Object.keys(errors).length) setDisabled(true);
    else setDisabled(false);
  }, [errors, formData]);

  const [alertMessage, setAlertMessage] = useState(false);
  const handleInputChange = (e, isNumeric = false) => {
    if (e.target.name === "vbc_customer_id" && !showCustomers)
      setShowCustomers(true);
    setFormData({
      ...formData,
      [e.target.name]: isNumeric
        ? +Number(e.target.value).toFixed(2)
        : e.target.value,
    });
  };

  const resetForm = function () {
    setFormData({
      phone_number: "",
      vbc_customer_id: "",
    });
    setErrors({});
  };

  const form = useRef(null);

  const addSubscriber = () => {
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    setLoaderSpinner(true);
    ottaxios
      .post("/ott/user_add_subscriber", formData, config)
      .then((res) => {
        if (res.data.status === "failure") {
          toast.error(`Error: ${res.data.message}`, { autoClose: 2000 });
        } else {
          props.onUpdate(formData.vbc_customer_id);
        }
        setLoaderSpinner(false);
      })
      .catch((error) => {
        console.log("error====", error);
        setLoaderSpinner(false);
        if (error.response.data.message) {
          toast.error(error.response.data.message, { autoClose: 2000 });
          return;
        }
        let errorMessage = "Something went wrong";
        if (error.response && error.response.data) {
          if (error.response.data["package_name"].length > 0) {
            errorMessage = error.response.data["package_name"][0];
          } else if (error.response.status === 500) {
            errorMessage = "Internal server error";
          } else if (error.response.status === 404) {
            errorMessage = "API endpoint not found";
          } else if (error.response.status === 401) {
            errorMessage = "Unauthorized!";
          }
        }
        toast.error(error.data.message, { autoClose: 2000 });
      });
  };

  // validations
  const submit = (e) => {
    e.preventDefault();
    e = e.target.name;

    const validationErrors = validate(formData);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);

    if (noErrors) {
      setAlertMessage(!alertMessage);
      addSubscriber();
    }

    // else {
    //   toast.error("Something went wrong");
    // }
  };

  const { validate } = useFormValidation(requiredFields);

  const checkFieldValidity = (fieldName) => {
    const validationErrors = validate(formData);
    let vErrors = {};
    if (validationErrors[fieldName]) {
      vErrors[fieldName] = validationErrors[fieldName];
    }

    const noErrors = Object.keys(vErrors).length === 0;

    if (noErrors) {
      setErrors((errors) => {
        delete errors[fieldName];
        return errors;
      });
    } else setErrors({ ...errors, ...{ [fieldName]: vErrors[fieldName] } });
  };

  function checkEmptyValue(e) {
    if (e.target.value == "") {
      e.target.classList.remove("not-empty");
    } else {
      e.target.classList.add("not-empty");
    }
  }

  //This function will be used for validation of individual fields
  const handleInputBlur = (e, fieldName) => {
    checkEmptyValue(e);
    checkFieldValidity(fieldName);
  };

  return (
    <Fragment>
      <br />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Form onSubmit={submit} id="myForm" onReset={resetForm} ref={form}>
              <Row>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Phone Number *</Label>
                      <Input
                        type="number"
                        name="phone_number"
                        onChange={(e) => {
                          if (e.target.value.toString().length > 10) return;
                          handleInputChange(e);
                        }}
                        onBlur={(e) => handleInputBlur(e, "phone_number")}
                        // onBlur={checkEmptyValue}
                        // draft
                        className={`form-control digits ${
                          formData && !formData.phone_number ? "" : "not-empty"
                        }`}
                        value={formData && formData.phone_number}
                      />
                    </div>

                    <span className="errortext">{errors.phone_number}</span>
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Customer ID *</Label>

                      <Input
                        type="text"
                        name="vbc_customer_id"
                        value={formData.vbc_customer_id}
                        className="form-control digits"
                        onChange={handleInputChange}
                        onBlur={(e) => handleInputBlur(e, "vbc_customer_id")}
                      />
                      {showCustomers && filteredCustomers.length ? (
                        <div
                          className="form-control digits"
                          style={{
                            marginTop: "10px",
                            height: "auto",
                            padding: 0,
                          }}
                        >
                          {filteredCustomers.map((customer) => (
                            <div
                              style={{
                                border: "0.5px solid lightgray",
                                padding: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setFormData((form) => ({
                                  ...form,
                                  vbc_customer_id: customer,
                                }));
                                setShowCustomers(false);
                              }}
                            >
                              {customer}
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                    <span className="errortext">{errors.vbc_customer_id}</span>
                  </FormGroup>
                </Col>
              </Row>

              <br />
              <Row>
                <span
                  className="sidepanel_border"
                  style={{ position: "relative" }}
                ></span>
                <Col>
                  <FormGroup className="mb-0">
                    <Button
                      color="btn btn-primary"
                      className="mr-3"
                      onClick={submit}
                      id="create_button"
                      disabled={loaderSpinneer ? loaderSpinneer : isDisabled}
                    >
                      {loaderSpinneer ? (
                        <Spinner size="sm" id="spinner"></Spinner>
                      ) : null}
                      Create
                    </Button>

                    <Button type="reset" color="btn btn-primary" id="resetid">
                      Reset
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <ErrorModal
          isOpen={showModal}
          toggle={() => setShowModal(false)}
          message={modalMessage}
          action={() => setShowModal(false)}
        />
      </Container>
    </Fragment>
  );
};

export default AddComboPlan;
