import React from "react";
import ChevronLeft from "../../../assets/images/chevron-left.svg";
import ChevronRight from "../../../assets/images/chevron-right.svg";
import ChevronLeftDisabled from "../../../assets/images/chevron-left-disabled.svg";
import ChevronRightDisabled from "../../../assets/images/chevron-right-disabled.svg";

import "./pagination.css";

const options = [5, 10, 15, 20, 25];

const Pagination = ({
  currentPageNo,
  currentRowsPerPage,
  handleRowsPerPageChange,
  handlePageChange,
  totalCount,
  // isPaginationDisabled,
}) => {
  console.log(
    "pages====",
    currentPageNo,
    currentRowsPerPage,
    totalCount
    // isPaginationDisabled
  );
  return (
    <div className="pagination-container">
      {/* <span style={{ marginRight: "10px" }}>Items per page: </span>
      <select
        name="rows"
        id="rows"
        onChange={(e) => handleRowsPerPageChange(e.target.value, currentPageNo)}
        defaultValue={options[1]}
      >
        {options.map((value) => (
          <option name={value} value={value}>
            {value}
          </option>
        ))}
      </select>
      <span style={{ marginLeft: "10px", marginRight: "10px" }}>
        {currentPageNo * currentRowsPerPage - currentRowsPerPage + 1} -{" "}
        {Math.min(currentPageNo * currentRowsPerPage, totalCount)} of{" "}
        {totalCount}
      </span> */}
      <div
        className="nav-icon-container"
        onClick={() => {
          if (currentPageNo === 1) return;
          handlePageChange(currentPageNo - 1);
        }}
      >
        <img src={currentPageNo === 1 ? ChevronLeftDisabled : ChevronLeft} />
      </div>
      <span style={{ marginLeft: "10px", marginRight: "10px" }}>
        {currentPageNo * currentRowsPerPage - currentRowsPerPage + 1}
        &nbsp; - &nbsp;
        {Math.min(currentPageNo * currentRowsPerPage, totalCount)}
        &nbsp; of &nbsp;
        {totalCount}
      </span>
      <div
        className="nav-icon-container"
        onClick={() => {
          if (currentPageNo * currentRowsPerPage >= totalCount) return;
          handlePageChange(currentPageNo + 1);
        }}
      >
        <img
          src={
            currentPageNo * currentRowsPerPage >= totalCount
              ? ChevronRightDisabled
              : ChevronRight
          }
        />
      </div>
    </div>
  );
};

export default Pagination;
