import React, { Fragment, useState } from "react";
import { Container, Row, Col, Label, FormGroup } from "reactstrap";
import RenewChangePlanModalCommon from "../../customermanagement/customer-details/RenewChangePlanModalCommon1";
import { COMBO_PLANS, OTT_SUBSCRIBERS } from "../../../../utils/permissions";

const PlanDetails = ({ plan }) => {
  const [isRenewChangePlanModalOpen, setIsRenewChangePlanModalOpen] =
    useState(false);

  const [selectedType, setSelectedType] = useState("ott");

  const onlinePerm = JSON.parse(
    localStorage.getItem("token") || "{}"
  )?.permissions.includes(
    selectedType === "ott"
      ? OTT_SUBSCRIBERS.GET_PAYMENT_LINK
      : COMBO_PLANS.GET_PAYMENT_LINK
  );

  const offlinePerm = JSON.parse(
    localStorage.getItem("token") || "{}"
  )?.permissions.includes(
    selectedType === "ott"
      ? OTT_SUBSCRIBERS.ACTIVE_PLAN_OFFLINE
      : COMBO_PLANS.ACTIVATE_PLAN_OFFLINE
  );

  return (
    <Fragment>
      <Container fluid={true}>
        {(onlinePerm || offlinePerm) && (
          <Row>
            <button
              className="btn btn-primary openmodal"
              style={{
                marginLeft: 10,
                marginTop: 10,
                marginBottom: 10,
                width: "250px",
              }}
              id="newbuuon"
              type="submit"
              onClick={() => {
                setSelectedType("ott");
                setIsRenewChangePlanModalOpen(true);
              }}
            >
              <b>
                <span
                  className="openmodal"
                  style={{ fontSize: "16px", marginLeft: "-9px" }}
                >
                  Change/Renew OTT Plan
                </span>
              </b>
            </button>
            <button
              className="btn btn-primary openmodal"
              style={{
                marginLeft: 10,
                marginTop: 10,
                marginBottom: 10,
                width: "250px",
              }}
              id="newbuuon"
              type="submit"
              onClick={() => {
                setSelectedType("combo");
                setIsRenewChangePlanModalOpen(true);
              }}
            >
              <b>
                <span
                  className="openmodal"
                  style={{ fontSize: "16px", marginLeft: "-9px" }}
                >
                  Change Combo Plan
                </span>
              </b>
            </button>
          </Row>
        )}
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <TextField name="Custmer Id" value={plan.vbc_customer_id} />
          <TextField
            name="Customer Name"
            value={`${plan.first_name} ${plan.last_name}`}
          />
          <TextField name="Phone Number" value={plan.phone_number} />
          <TextField name="Operator Code" value={plan.operator_code} />
          <TextField name="Status" value={plan.status} />
          <TextField name="Plan Name" value={plan.plan_name || "-"} />
          <TextField name="Plan Code" value={plan.plan_code || "-"} />
          <TextField
            name="Plan Expiry"
            value={
              plan.plan_expiry_date
                ? new Date(plan.plan_expiry_date).toDateString()
                : "-"
            }
          />
          <TextField
            name="Plan Duration"
            value={`${plan.plan_duration || "-"} month(s)`}
          />
        </Row>
        {isRenewChangePlanModalOpen && (
          <RenewChangePlanModalCommon
            isRenewChangePlanModalOpen={isRenewChangePlanModalOpen}
            toggleRenewChangePlanModalOpen={setIsRenewChangePlanModalOpen}
            customerId={plan.vbc_customer_id}
            walletAmount={plan.wallet}
            isOtt={selectedType === "ott"}
          />
        )}
      </Container>
    </Fragment>
  );
};

export default PlanDetails;

const TextField = ({ name, value }) => (
  <FormGroup>
    <div className="input_wrap ml-3">
      <Label className="kyc_label">{name}</Label>
      <input
        className={`form-control digits not-empty`}
        id="afterfocus"
        type="text"
        name="package_name"
        style={{ border: "none", outline: "none" }}
        value={value}
        // onChange={handleChange}
        disabled={true}
      ></input>
    </div>
  </FormGroup>
);
